import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";

import { AnnouncementsConfigurations } from "@zixi/models";

import { AnnouncementsConfigurationsService } from "../announcements-configurations.service";
@Injectable({
    providedIn: "root"
})
export class AnnouncementsConfigurationsResolverService {
    constructor(private announcementsConfigurationsService: AnnouncementsConfigurationsService) {}

    resolve(): Observable<AnnouncementsConfigurations[]> | Observable<never> {
        return new Observable((observe: Subscriber<AnnouncementsConfigurations[]>) => {
            this.announcementsConfigurationsService
                .refreshAnnouncementsConfigurations()
                .subscribe((announcementsConfigurations: AnnouncementsConfigurations[]) => {
                    observe.next(announcementsConfigurations);
                    observe.complete();
                });
        });
    }
}
