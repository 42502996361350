import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";

// Routes
import { AnnouncementsConfigurationsRouting } from "./announcements-configurations.routing";

// Components
import { AnnouncementsConfigurationsListComponent } from "./announcements-configurations-list/announcements-configurations-list.component";
import { AnnouncementConfigurationsComponent } from "./announcement-configurations/announcement-configurations.component";
import { AnnouncementConfigurationsDetailsComponent } from "./announcement-configurations/announcement-configurations-details/announcement-configurations-details.component";
import { AnnouncementConfigurationsFormComponent } from "./announcement-configurations-form/announcement-configurations-form.component";

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, SharedModule, AnnouncementsConfigurationsRouting, FontAwesome],
    declarations: [
        AnnouncementsConfigurationsListComponent,
        AnnouncementConfigurationsComponent,
        AnnouncementConfigurationsDetailsComponent,
        AnnouncementConfigurationsFormComponent
    ]
})
export class AnnouncementsConfigurationsModule {}
