import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";

import { Constants } from "src/app/constants/constants";
import { TitleService } from "src/app/services/title.service";
import { AnnouncementsConfigurationsService } from "../announcements-configurations.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { prepareToServer } from "src/app/utils/prepare-to-server";

import { AnnouncementsConfigurations } from "@zixi/models";

@Component({
    selector: "app-announcement-configurations-form",
    templateUrl: "./announcement-configurations-form.component.html",
    providers: [TitleCasePipe]
})
export class AnnouncementConfigurationsFormComponent implements OnInit {
    announcementConfigurations: AnnouncementsConfigurations;
    announcementConfigurationsId: number;

    isLoading = true;
    action: string;
    isEdit = false;
    saving = false;
    announcementsConfigurationsListUrl = Constants.urls.announcements_configurations;

    form: UntypedFormGroup;
    isFormSubmitted = false;
    customerFilterOptions = ["all", "aws_clusters_only"];
    severityOptions = ["info", "error", "warning"];

    constructor(
        private announcementsConfigurationsService: AnnouncementsConfigurationsService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: TitleService,
        private titleCasePipe: TitleCasePipe,
        private mixpanelService: MixpanelService,
        private translateService: TranslateService,
        private sharedService: SharedService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.announcementConfigurationsId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.announcementConfigurationsId) {
                this.isEdit = true;

                this.announcementsConfigurationsService
                    .refreshAnnouncementConfigurations(this.announcementConfigurationsId, true)
                    .subscribe(announcementConfigurations => {
                        this.announcementConfigurations = Object.assign({}, announcementConfigurations);
                        this.prepForm();
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        });
    }

    ngOnInit(): void {
        this.prepForm();
    }

    prepForm() {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(this.announcementConfigurations?.name ?? "", [Validators.required]),
            isActive: new UntypedFormControl(this.announcementConfigurations?.is_active ?? true),
            startTime: new UntypedFormControl(this.announcementConfigurations?.start_time || null, [
                Validators.required
            ]),
            endTime: new UntypedFormControl(this.announcementConfigurations?.end_time || null, [Validators.required]),
            isCritical: new UntypedFormControl(this.announcementConfigurations?.is_critical ?? false),
            isDismissible: new UntypedFormControl(this.announcementConfigurations?.is_dismissible ?? false),
            message: new UntypedFormControl(this.announcementConfigurations?.message ?? "", [
                Validators.required,
                Validators.minLength(2)
            ]),
            moreInfoUrl: new UntypedFormControl(this.announcementConfigurations?.more_info_url ?? ""),
            severity: new UntypedFormControl(this.announcementConfigurations?.severity ?? [], [Validators.required]),
            isAdminOnly: new UntypedFormControl(this.announcementConfigurations?.is_admin_only ?? false),
            customerFilter: new UntypedFormControl(this.announcementConfigurations?.customer_filter ?? [], [
                Validators.required
            ])
        });

        // Set Title
        this.titleService.setTitle(
            this.translateService.instant("ANNOUNCEMENT_CONFIGURATIONS") +
                " - " +
                (this.action ? this.titleCasePipe.transform(this.action) : "New") +
                " " +
                (this.announcementConfigurations?.id ? this.announcementConfigurations.id : "")
        );
    }

    async onSubmit() {
        this.saving = true;
        this.isFormSubmitted = true;

        if (this.isInvalidForm) {
            this.saving = false;
            return;
        }

        const announcementConfigurations = prepareToServer(Object.assign({}, this.form.value));

        let result: boolean | AnnouncementsConfigurations = false;

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(
                announcementConfigurations,
                this.announcementConfigurations,
                [],
                {}
            );
            result = await this.announcementsConfigurationsService.updateAnnouncementConfigurations(
                this.announcementConfigurationsId,
                changedData
            );
        } else {
            result = await this.announcementsConfigurationsService.createAnnouncementConfigurations(
                announcementConfigurations as unknown as AnnouncementsConfigurations
            );
            if (result) {
                this.mixpanelService.sendEvent("create announcement configurations");
            }
        }

        if (result) this.router.navigate([this.announcementsConfigurationsListUrl, result.id]);
        this.saving = false;
    }

    get isInvalidForm() {
        return this.isFormSubmitted && this.form.invalid;
    }
}
