import { Component, Input } from "@angular/core";

import { AnnouncementsConfigurations } from "@zixi/models";

@Component({
    selector: "app-announcement-configurations-details",
    templateUrl: "./announcement-configurations-details.component.html"
})
export class AnnouncementConfigurationsDetailsComponent {
    @Input() announcementConfigurations: AnnouncementsConfigurations;
}
