<content-loading-animation *ngIf="isLoading"></content-loading-animation>

<app-form-wrapper [formGroup]="form" class="content-area pt-0" *ngIf="!isLoading" [titleMsg]="'ANNOUNCEMENT_CONFIGURATIONS' | translate" [isEdit]="isEdit">
    <app-input-generic-field
        [textFieldName]="'NAME' | translate"
        [formControl]="form.controls.name"
        type="text"
        [isParentFormSubmitted]="isFormSubmitted"
        [disabled]="saving"
    ></app-input-generic-field>

    <section id="activation">
        <app-form-section-title [title]="'ACTIVATION' | translate"></app-form-section-title>

        <app-checkbox-field [label]="'IS_ACTIVE' | translate" [formControl]="form.controls.isActive" [id]="'isActive'"></app-checkbox-field>

        <app-zx-date-picker
            [formControl]="form.controls.startTime"
            [label]="'START_TIME' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
            [allDates]="true"
        ></app-zx-date-picker>

        <app-zx-date-picker
            [formControl]="form.controls.endTime"
            [label]="'END_TIME' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
            [allDates]="true"
        ></app-zx-date-picker>
    </section>

    <section id="content">
        <app-form-section-title [title]="'CONTENT' | translate"></app-form-section-title>

        <app-checkbox-field [label]="'IS_CRITICAL' | translate" [formControl]="form.controls.isCritical" [id]="'isCritical'"></app-checkbox-field>

        <app-checkbox-field [label]="'IS_DISMISSIBLE' | translate" [formControl]="form.controls.isDismissible" [id]="'isDismissible'"></app-checkbox-field>

        <app-input-generic-field
            [textFieldName]="'MESSAGE' | translate"
            [formControl]="form.controls.message"
            type="text"
            [isParentFormSubmitted]="isFormSubmitted"
            [disabled]="saving"
        ></app-input-generic-field>

        <app-input-generic-field [formControl]="form.controls.moreInfoUrl" [textFieldName]="'MORE_INFO_URL' | translate" type="text"></app-input-generic-field>

        <app-zx-string-selection
            [label]="'SEVERITY' | translate"
            [formControl]="form.controls.severity"
            [selectionOptions]="severityOptions"
            [isParentFormSubmitted]="isFormSubmitted"
            [disabled]="saving"
        ></app-zx-string-selection>
    </section>

    <section id="filters">
        <app-form-section-title [title]="'FILTERS' | translate"></app-form-section-title>

        <app-checkbox-field [label]="'IS_ADMIN_ONLY' | translate" [formControl]="form.controls.isAdminOnly" [id]="'isAdminOnly'"></app-checkbox-field>

        <app-zx-string-selection
            [label]="'CUSTOMER_FILTER' | translate"
            [formControl]="form.controls.customerFilter"
            [selectionOptions]="customerFilterOptions"
            [isParentFormSubmitted]="isFormSubmitted"
            [disabled]="saving"
        ></app-zx-string-selection>
    </section>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="isFormSubmitted"
        (submit)="onSubmit()"
        [cancelUrlNavigate]="announcementsConfigurationsListUrl"
        [isSaving]="saving"
        [isEdit]="false"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
