import { Component, OnDestroy, OnInit, ComponentRef } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import * as _ from "lodash";
import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { KeyMap, UserPermissions } from "../../../models/shared";
import { UsersService } from "../../account-management/users/users.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { SharedService } from "../../../services/shared.service";

import { AnnouncementsConfigurations } from "@zixi/models";
import { AnnouncementsConfigurationsService } from "../announcements-configurations.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";

@Component({
    selector: "app-announcements-configurations-list",
    templateUrl: "./announcements-configurations-list.component.html",
    providers: [TitleCasePipe, DatePipe]
})
export class AnnouncementsConfigurationsListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    selectedAnnouncementConfigurationsId: number;
    selectedRows: Array<AnnouncementsConfigurations> = [];

    searchTermArray: string[];
    filteredAnnouncementsConfigurations: AnnouncementsConfigurations[];

    urls = Constants.urls;
    userPermissions: UserPermissions;

    private routeSubscription: Subscription;
    private announcementsConfigurationsSubscription: Subscription;
    private announcementsConfigurationsRefreshSubscription: Subscription;

    announcementsConfigurations: AnnouncementsConfigurations[];
    private announcementsConfigurationsBS$ = new BehaviorSubject<AnnouncementsConfigurations[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            width: 200,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnnouncementsConfigurations>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: AnnouncementsConfigurations): string => row.name,
            textValue: (row: AnnouncementsConfigurations): string => row.name
        },
        {
            header: this.translateService.instant("MESSAGE"),
            columnDef: "message",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnnouncementsConfigurations>(
                row => row.message,
                row => row.message,
                () => true
            ),
            sortBy: (row: AnnouncementsConfigurations): string => row.message,
            textValue: (row: AnnouncementsConfigurations): string => row.message
        },
        {
            header: this.translateService.instant("MORE_INFO_URL"),
            columnDef: "more_info_url",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnnouncementsConfigurations>(
                row => row.more_info_url,
                row => row.more_info_url,
                () => true
            ),
            sortBy: (row: AnnouncementsConfigurations): string => row.more_info_url,
            textValue: (row: AnnouncementsConfigurations): string => row.more_info_url
        },
        {
            header: this.translateService.instant("CUSTOMER_FILTER"),
            columnDef: "customer_filter",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnnouncementsConfigurations>(
                row => row.customer_filter,
                row => row.customer_filter,
                () => true
            ),
            sortBy: (row: AnnouncementsConfigurations): string => row.customer_filter,
            textValue: (row: AnnouncementsConfigurations): string => row.customer_filter
        },
        {
            header: this.translateService.instant("IS_ADMIN_ONLY"),
            columnDef: "is_admin_only",
            width: 80,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<AnnouncementsConfigurations>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.is_admin_only ? true : false;
            },
            sortBy: (row: AnnouncementsConfigurations) =>
                row.is_admin_only ? this.translateService.instant("IS_ADMIN_ONLY") : "",
            textValue: (row: AnnouncementsConfigurations) =>
                row.is_admin_only ? this.translateService.instant("IS_ADMIN_ONLY") : ""
        },
        {
            header: this.translateService.instant("IS_DISMISSIBLE"),
            columnDef: "is_dismissible",
            width: 80,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<AnnouncementsConfigurations>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.is_dismissible ? true : false;
            },
            sortBy: (row: AnnouncementsConfigurations) =>
                row.is_dismissible ? this.translateService.instant("IS_DISMISSIBLE") : "",
            textValue: (row: AnnouncementsConfigurations) =>
                row.is_dismissible ? this.translateService.instant("IS_DISMISSIBLE") : ""
        },
        {
            header: this.translateService.instant("IS_CRITICAL"),
            columnDef: "is_critical",
            width: 80,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<AnnouncementsConfigurations>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.is_critical ? true : false;
            },
            sortBy: (row: AnnouncementsConfigurations) =>
                row.is_critical ? this.translateService.instant("IS_CRITICAL") : "",
            textValue: (row: AnnouncementsConfigurations) =>
                row.is_critical ? this.translateService.instant("IS_CRITICAL") : ""
        },
        {
            header: this.translateService.instant("SEVERITY"),
            columnDef: "severity",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnnouncementsConfigurations>(
                row => this.titlecasePipe.transform(row.severity),
                row => this.titlecasePipe.transform(row.severity),
                () => true
            ),
            sortBy: (row: AnnouncementsConfigurations): string => row.severity,
            textValue: (row: AnnouncementsConfigurations): string => row.severity
        },
        {
            header: this.translateService.instant("IS_ACTIVE"),
            columnDef: "is_active",
            width: 80,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<AnnouncementsConfigurations>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.is_active ? true : false;
            },
            sortBy: (row: AnnouncementsConfigurations) =>
                row.is_active ? this.translateService.instant("IS_ACTIVE") : "",
            textValue: (row: AnnouncementsConfigurations) =>
                row.is_active ? this.translateService.instant("IS_ACTIVE") : ""
        },
        {
            header: this.translateService.instant("START_TIME"),
            columnDef: "start_time",
            width: 120,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<AnnouncementsConfigurations>(
                row => row.start_time as string,
                "M/d/yy, h:mm a"
            ),
            sortBy: (row: AnnouncementsConfigurations) => new Date(row.start_time).getTime()
        },
        {
            header: this.translateService.instant("END_TIME"),
            columnDef: "end_time",
            width: 120,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<AnnouncementsConfigurations>(
                row => row.end_time as string,
                "M/d/yy, h:mm a"
            ),
            sortBy: (row: AnnouncementsConfigurations) => new Date(row.end_time).getTime()
        }
    ];

    constructor(
        private router: Router,
        private announcementsConfigurationsService: AnnouncementsConfigurationsService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translateService: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        this.titleService.setTitle(this.translateService.instant("ANNOUNCEMENTS_CONFIGURATIONS"));

        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.selectedAnnouncementConfigurationsId = parseInt(event.snapshot.params.id, 10);
                } else {
                    this.selectedAnnouncementConfigurationsId = null;
                }
                this.updateSelectedRows();
            });
    }

    ngOnInit(): void {
        this.loading = true;

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        this.announcementsConfigurationsSubscription =
            this.announcementsConfigurationsService.announcementsConfigurations.subscribe(
                announcementsConfigurations => {
                    this.announcementsConfigurations = announcementsConfigurations;
                    if (this.announcementsConfigurations) {
                        this.prepTableData();
                        this.loading = false;
                    }
                }
            );

        // Start Auto Refresh
        this.startAnnouncementsConfigurationsRefresh();

        setTimeout(() => {
            this.updateSelectedRows();
        });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.announcementsConfigurationsSubscription.unsubscribe();
        this.stopAnnouncementsConfigurationsRefresh();
    }

    startAnnouncementsConfigurationsRefresh() {
        this.announcementsConfigurationsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopAnnouncementsConfigurationsRefresh() {
        this.announcementsConfigurationsRefreshSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const announcementsConfigurationsRefresh = this.announcementsConfigurationsService
            .refreshAnnouncementsConfigurations(true)
            .toPromise();

        const announcementConfigurationsRefresh = this.selectedAnnouncementConfigurationsId
            ? this.announcementsConfigurationsService
                  .refreshAnnouncementConfigurations(this.selectedAnnouncementConfigurationsId, true)
                  .toPromise()
            : null;

        await Promise.all([announcementsConfigurationsRefresh, announcementConfigurationsRefresh]);

        this.refreshing = false;
    }

    updateSelectedRows() {
        if (this.selectedAnnouncementConfigurationsId) {
            const existing = this.announcementsConfigurations.find(
                p => p.id === this.selectedAnnouncementConfigurationsId
            );
            this.selectedRows = existing ? [existing] : [];
        } else this.selectedRows = [];
    }

    multiDelete() {
        this.multiAction("DELETE", async (announcementConfigurations: AnnouncementsConfigurations) =>
            this.announcementsConfigurationsService.deleteAnnouncementConfigurations(announcementConfigurations)
        );
    }

    async multiAction(
        action: string,
        func: (announcementConfigurations: AnnouncementsConfigurations) => Promise<unknown>
    ) {
        const result = await this.modalService.confirmMultiple(
            action,
            "ANNOUNCEMENTS_CONFIGURATIONS",
            this.selectedRows,
            func
        );
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translateService.instant(action).toLowerCase() + " multiple users");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    private prepTableData() {
        if (this.announcementsConfigurations) {
            this.announcementsConfigurationsBS$.next([...this.announcementsConfigurations]);
        }
    }

    get announcementsConfigurations$() {
        return this.announcementsConfigurationsBS$.asObservable();
    }

    selectRow = (announcementConfiguration: AnnouncementsConfigurations) => {
        this.selectedAnnouncementConfigurationsId = announcementConfiguration.id;
        this.router.navigate([Constants.urls.announcements_configurations, announcementConfiguration.id]);
    };
}
