import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { AnnouncementConfigurationsComponent } from "./announcement-configurations/announcement-configurations.component";
import { AnnouncementConfigurationsFormComponent } from "./announcement-configurations-form/announcement-configurations-form.component";
import { AnnouncementsConfigurationsListComponent } from "./announcements-configurations-list/announcements-configurations-list.component";
import { AnnouncementsConfigurationsResolverService } from "./announcements-configurations-list/announcements-configurations-resolver.service";

const announcementsConfigurationsRoutes: Routes = [
    {
        path: Constants.urls.announcements_configurations + "/new",
        component: AnnouncementConfigurationsFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.announcements_configurations + "/:id/:action",
        component: AnnouncementConfigurationsFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.announcements_configurations,
        component: AnnouncementsConfigurationsListComponent,
        resolve: {
            announcementsConfigurations: AnnouncementsConfigurationsResolverService
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: ":id",
                component: AnnouncementConfigurationsComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(announcementsConfigurationsRoutes)],
    exports: [RouterModule]
})
export class AnnouncementsConfigurationsRouting {}
